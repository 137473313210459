import cacheAngularTemplate from 'cacheAngularTemplate';
import template from '../views/entity_metadata_form.html';
import angularModule from './content_item_editor_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('entityMetadataForm', [
    '$injector',

    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const ShareService = $injector.get('Navigation.ShareService');
        const SiteMetadata = $injector.get('SiteMetadata');
        const ngToast = $injector.get('ngToast');

        return {
            restrict: 'E',
            scope: {
                contentItem: '<',
                urlPrefix: '@',
            },
            transclude: true,
            templateUrl,
            link(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                //---------------------------
                // SEO Properties
                //---------------------------

                Object.defineProperty(scope, 'seoTitleLocked', {
                    get() {
                        return scope.$$seoTitleLocked;
                    },
                    set(val) {
                        scope.$$seoTitleLocked = val;
                        // if we're relocking the value, sync it back up with the original
                        if (val) {
                            scope.contentItem.entity_metadata.title = scope.contentItem.title;
                        }
                    },
                });

                Object.defineProperty(scope, 'seoDescriptionLocked', {
                    get() {
                        return scope.$$seoDescriptionLocked;
                    },
                    set(val) {
                        scope.$$seoDescriptionLocked = val;
                        // if we're relocking the value, sync it back up with the original
                        if (val) {
                            scope.contentItem.entity_metadata.description = scope.contentItem.description;
                        }
                    },
                });

                Object.defineProperty(scope, 'seoCanonicalUrlLocked', {
                    get() {
                        return scope.$$seoCanonicalUrlLocked;
                    },
                    set(val) {
                        scope.$$seoCanonicalUrlLocked = val;
                        // if we're relocking the value, sync it back up with the current entity_metadata.title
                        scope.contentItem.entity_metadata.canonical_url = SiteMetadata.seoCanonicalUrlFromTitle(
                            scope.urlPrefix,
                            scope.contentItem.entity_metadata.title,
                            scope.contentItem.id,
                        );
                    },
                });

                //---------------------------
                // Share Support
                //---------------------------

                function share(provider) {
                    ShareService.openShareWindow(
                        provider,
                        SiteMetadata.contentCompletedShareInfo(scope.currentUser, scope.contentItem),
                    );
                }

                scope.generateTwitterIntentLink = () => {
                    share('twitter');
                };

                scope.generateFacebookIntentLink = () => {
                    share('facebook');
                };

                scope.generateLinkedinIntentLink = () => {
                    share('linkedin');
                };

                //---------------------------
                // SEO Watches / Init
                //---------------------------

                scope.initializeLockedSeoFields = () => {
                    if (scope.contentItem.title === scope.contentItem.entity_metadata.title) {
                        scope.seoTitleLocked = true;
                    }
                    if (scope.contentItem.description === scope.contentItem.entity_metadata.description) {
                        scope.seoDescriptionLocked = true;
                    }
                    if (
                        !scope.contentItem.entity_metadata.canonical_url ||
                        SiteMetadata.seoCanonicalUrlFromTitle(
                            scope.urlPrefix,
                            scope.contentItem.entity_metadata.title,
                            scope.contentItem.id,
                        ) === scope.contentItem.entity_metadata.canonical_url
                    ) {
                        scope.seoCanonicalUrlLocked = true;
                    }
                };

                scope.$watch('contentItem', () => {
                    if (scope.contentItem) {
                        scope.initializeLockedSeoFields();
                    }
                });

                scope.seoImageUploaded = s3Asset => {
                    scope.contentItem.entity_metadata.image = s3Asset;
                };

                scope.imageEdited = s3Asset => {
                    scope.contentItem.entity_metadata.image = s3Asset;
                };

                scope.$watch('contentItem.title', changedTitle => {
                    // if the seo title is locked, update it with the changed value
                    if (scope.seoTitleLocked) {
                        scope.contentItem.entity_metadata.title = changedTitle;
                    }
                });

                scope.$watch('contentItem.entity_metadata.title', () => {
                    // if the canonical url is locked, also update it on title update
                    if (scope.seoCanonicalUrlLocked) {
                        scope.contentItem.entity_metadata.canonical_url = SiteMetadata.seoCanonicalUrlFromTitle(
                            scope.urlPrefix,
                            scope.contentItem.entity_metadata.title,
                            scope.contentItem.id,
                        );
                    }
                });

                scope.$watch('contentItem.description', changedDescription => {
                    // if originalTitle is the same as seoTitle, this field is locked and update seoTitle
                    if (scope.seoDescriptionLocked) {
                        scope.contentItem.entity_metadata.description = changedDescription;
                    }
                });

                // seo metadata saved outside the context of normal contentItem editing, so that
                // we can tweak values without having to re-save / re-publish
                scope.saveMetadata = () => {
                    scope.contentItem.entity_metadata.save().then(response => {
                        scope.contentItem.entity_metadata = response.result;

                        ngToast.create({
                            content: 'Metadata has been updated!',
                            className: 'success',
                        });
                    });
                };
            },
        };
    },
]);
