import angularModule from 'ContentItemEditor/angularModule/scripts/content_item_editor_module';
import template from 'ContentItemEditor/angularModule/views/content_item_edit_locale.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('contentItemEditLocale', [
    '$injector',

    $injector => {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const Locale = $injector.get('Locale');
        const contentItemEditorLists = $injector.get('contentItemEditorLists');

        return {
            restrict: 'E',
            scope: {
                contentItem: '<',
                loaded: '=?', // two-way binding so parent can know if this is loaded
            },
            transclude: true,
            templateUrl,
            link(scope) {
                NavigationHelperMixin.onLink(scope);

                scope.contentItemsById = undefined; // we put this on the scope for testing purpuses
                scope.proxy = {};
                scope.nonEnglishLocales = Locale.editorLocalesWithoutEnglish;

                const selectizeConfigBase = {
                    maxItems: 1,
                    labelField: 'titleWithTag',
                    sortField: 'titleWithTag',
                    searchField: 'titleWithTag',
                    valueField: 'id',
                };
                scope.selectizeConfig = {};
                Locale.editorLocales.forEach(locale => {
                    scope.selectizeConfig[locale.code] = _.extend({}, selectizeConfigBase, {
                        placeholder: ` --- ${locale.title} version`,
                    });
                });

                scope.$watchCollection('proxy.linkedContentItemIds', linkedContentItemIds => {
                    const localePack = scope.contentItem && scope.contentItem.locale_pack;
                    if (
                        linkedContentItemIds &&
                        scope.contentItem.locale === 'en' &&
                        localePack &&
                        scope.contentItemsById
                    ) {
                        _.forEach(scope.nonEnglishLocales, locale => {
                            const contentItem = scope.contentItemsById[linkedContentItemIds[locale.code]];
                            localePack.setContentItemForLocale(locale.code, contentItem);
                        });
                    }
                });

                function initializeLinkedContentItemIDs() {
                    const localePack = scope.contentItem && scope.contentItem.locale_pack;
                    if (localePack && scope.contentItemsById) {
                        scope.proxy.linkedContentItemIds = {};
                        _.forEach(scope.nonEnglishLocales, locale => {
                            scope.proxy.linkedContentItemIds[locale.code] = localePack.contentItemIdForLocale(
                                locale.code,
                            );
                        });
                    } else if (!localePack) {
                        scope.proxy.linkedContentItemIds = {};
                    }
                }
                scope.$watchGroup(['contentItem.locale_pack', 'contentItemsById'], initializeLinkedContentItemIDs);

                function loadLinkableContentItems() {
                    const locales = _.map(scope.nonEnglishLocales, 'code');
                    const filters = {
                        locale_pack_id: [null, scope.contentItem.localePackId],
                    };

                    // We use onComplete instead of onLoad because otherwise
                    // initializeLinkedContentItemIDs will be called after only
                    // one locale is loaded, causing an item to get removed by
                    // localePack.setContentItemForLocale
                    contentItemEditorLists
                        .load(scope.contentItem.factoryName, locales, filters)
                        .onComplete(contentItems => {
                            scope.contentItemsById = _.keyBy(contentItems, 'id');

                            // If we have switched from another locale to English, we will
                            // load up the existing version of this item in the api call.  Do
                            // not try to link this item to itself
                            delete scope.contentItemsById[scope.contentItem.id];
                        });
                }

                scope.$watch('contentItem.locale', (newLocale, oldLocale) => {
                    // We only mess with the locale pack when a locale
                    // is changing, not when the initial value is set
                    if (newLocale && oldLocale && newLocale !== oldLocale) {
                        if (newLocale === 'en') {
                            // If we switched away from English and back, we want the same
                            // locale pack id.  Otherwise, we will not be able to add the
                            // old items back in.
                            scope.contentItem.locale_pack = originalLocalePack;

                            // If there was no originalLocalePack, create one now
                            scope.contentItem.ensureLocalePack();
                        } else {
                            scope.contentItem.locale_pack = null;
                        }
                    }

                    // load up the linkable items if in English
                    if (newLocale === 'en') {
                        loadLinkableContentItems();
                    } else {
                        scope.loaded = true;
                        scope.contentItemsById = undefined;
                    }
                });

                // See above where originalLocalePack is used
                let originalLocalePack;
                scope.$watch('contentItem.locale_pack', localePack => {
                    if (localePack && scope.contentItem.locale === 'en') {
                        originalLocalePack = localePack;
                    }
                });

                scope.$watchGroup(['contentItemsById', 'contentItem.locale'], () => {
                    if (scope.contentItemsById && scope.contentItem) {
                        scope.loaded = true;
                        scope.proxy.availableContentItems = _.chain(scope.contentItemsById)
                            .values()
                            .groupBy('locale')
                            .value();
                        _.forEach(scope.nonEnglishLocales, locale => {
                            if (!scope.proxy.availableContentItems[locale.code]) {
                                scope.proxy.availableContentItems[locale.code] = [];
                            }
                        });
                    }
                });

                scope.openEditorFor = contentItemId => {
                    const ContentItemKlass = scope.contentItem.constructor;
                    scope.loadUrl(ContentItemKlass.editorUrl(contentItemId), '_blank');
                };
            },
        };
    },
]);
