
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.content_item_editor = window.Smartly.locales.modules.en.back_royal.content_item_editor || {};
window.Smartly.locales.modules.en.back_royal.content_item_editor.content_item_edit_locale = window.Smartly.locales.modules.en.back_royal.content_item_editor.content_item_edit_locale || {};
window.Smartly.locales.modules.en.back_royal.content_item_editor.content_item_edit_locale = {...window.Smartly.locales.modules.en.back_royal.content_item_editor.content_item_edit_locale, ...{
    "translation_of": "This is a translation of:",
    "unlink": "Unlink from translations",
    "groups_are_shared": "Groups are shared amongst all linked translations."
}
}
    