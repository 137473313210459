import angularModule from 'ContentItemEditor/angularModule/scripts/content_item_editor_module';
import template from 'ContentItemEditor/angularModule/views/duplicate_content_item.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('duplicateContentItem', [
    '$injector',
    function factory($injector) {
        const DialogModal = $injector.get('DialogModal');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const Locale = $injector.get('Locale');
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                contentItem: '<',
                hideIsTranslation: '<',
            },
            link(scope) {
                NavigationHelperMixin.onLink(scope);
                scope.dupeOptions = {
                    isTranslation: false,
                    newItemLocale: undefined,
                    title: `Duplicated from '${scope.contentItem.title}'`,
                };

                scope.$watch('contentItem.locale', locale => {
                    // filter out the original contentItem's locale for possible translation options
                    scope.localeOptions = _.reject(Locale.editorLocalesWithoutEnglish, entry => entry.code === locale);
                });

                scope.cancel = () => {
                    DialogModal.hideAlerts();
                };

                scope.save = () => {
                    scope.saving = true;

                    scope.contentItem
                        .duplicate(
                            {
                                locale: scope.dupeOptions.newItemLocale,
                                title: scope.dupeOptions.title,
                            },
                            {
                                in_same_locale_pack: scope.dupeOptions.isTranslation,
                            },
                        )
                        .then(
                            response => {
                                const duplicatedContentItem = response.result;
                                DialogModal.hideAlerts();
                                if (!duplicatedContentItem.editorUrl) {
                                    throw new Error('No editor url');
                                }
                                scope.loadRoute(duplicatedContentItem.editorUrl);
                            },
                            err => {
                                scope.saving = false;
                                throw err;
                            },
                        );
                };

                scope.$watchGroup(['dupeOptions.isTranslation', 'dupeOptions.newItemLocale'], () => {
                    const contentItem = scope.contentItem;
                    const isTranslation = scope.dupeOptions.isTranslation;
                    const newItemLocale = scope.dupeOptions.newItemLocale;

                    if ((isTranslation && !newItemLocale) || newItemLocale === contentItem.locale) {
                        scope.dupeOptions.newItemLocale = scope.localeOptions[0].code;
                        // we can return now, since we just changed the
                        // locale, so the $watch is gonna bring us right
                        // back in here.  No reason to do it twice
                        return;
                    }

                    // give a warning if another contentItem will be removed from the locale pack
                    const existing =
                        scope.contentItem.locale_pack &&
                        scope.contentItem.locale_pack.contentItemForLocale(scope.dupeOptions.newItemLocale);

                    if (existing) {
                        scope.warningMessage = `This translation will replace ${existing.title}`;
                    } else {
                        scope.warningMessage = null;
                    }
                });
            },
        };
    },
]);
