/* eslint-disable no-use-before-define */
import angularModule from 'ContentItemEditor/angularModule/scripts/content_item_editor_module';
/*

    This factory allows the caching of lists of content items in the editor.

    Usage:

        // load all english playlists and trigger a callback when they are available
        contentItemEditorLists.load('Playlist', 'en').onLoad(function(playlists) {});

        // load all spanish or chinese streams
        contentItemEditorLists.load('Lesson.Stream', ['es', 'zh']).onLoad(function(streams) {});

        // filter the results before triggering callback
        contentItemEditorLists.load('Lesson', 'en', {locale_pack_id: 'some_id'}).onLoad(function(lessons) {});

        // filter the results with a custom filter function before triggering callback
        contentItemEditorLists.load('Lesson', 'en', { customFilter: contentItem => { ... } }).onLoad(function(lessons) {});

        // wait until all data is loaded, rather than triggering the
        // callback each time more data is loaded
        contentItemEditorLists.load('Playlist', 'en').onComplete(function(playlists) {});

    Details:

        LOADING AND CACHING CONTENT:

        The first time a call to load() is made with a particular klass name and
        locale, a request is sent over the API to load the working versions for all
        non-archived content items for that class and locale.

        On subsequent calls to load() for the same klass name and locale, the callback
        passed to onLoad() will be triggered immediately with the cached content items.
        Then, if more than 10 seconds have passed since the last request was sent, a new
        request will be sent over the api, loading only content items that have been
        updated since the last request was sent.  When that request returns, the callback
        passed to onLoad() will be triggered again with the complete, updated list of
        content items.

        To be clear, the callback passed to onLoad() will be triggered twice in cases where
        data needs to be refreshed from the server.

        Content items are cached in the contentItemEditorLists object, so refreshing the
        browser kills the cache (See https://trello.com/c/RmAwjCY8/562-feat-save-contentitemeditorlists-in-local-storage)

        We do not (yet) handle deletions, so deleted items will never be un-cached.

        FILTERS:

        The third argument to load is an object or an array defining client-side filters to apply
        to the content items.  This does not affect the api calls that are made.  It only
        affects which content items are send to the callback passed to onLoad().  Only
        filters are supported.  See the _filter to see which ones.

    Testing:

        It is still possible to test components that use contentItemEditorLists with the regular
        Iguana mocks, like Playlist.expects(...), but on order to do so you need to understand
        what is going on inside of contentItemEditorLists.  It is "mroe correct" to mock
        out contentItemEditorLists instead.  Look in list_lessons_dir_spec.js or search our test
        code for `spyOn(contentItemEditorLists, 'load')` to find an example.

*/
angularModule.factory('contentItemEditorLists', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');

        const CacheItem = SuperModel.subclass(() => ({
            initialize(klassName, locale) {
                this.klassName = klassName;
                this.locale = locale;
            },

            onLoad(cb) {
                return this._loadAndTriggerCallback(cb, true);
            },

            onComplete(cb) {
                return this._loadAndTriggerCallback(cb, false);
            },

            _loadAndTriggerCallback(cb, triggerWithPartialResult) {
                const self = this;

                // If we have not yet made a request, make one
                if (!this.contentItemPromise) {
                    self._load();
                }

                // If the last request was too long ago, then we are going
                // to check for updates
                const shouldCheckForUpdates =
                    self.lastLoadAt.getTime() < contentItemEditorLists.now().getTime() - 10 * 1000;

                // If our data is fresh enough that we don't need to check for
                // updates, or if the user called onLoad() (not onComplete()),
                // meaning that they want us to progressively return results,
                // then we trigger the callback with the data we already have
                if (!shouldCheckForUpdates || triggerWithPartialResult) {
                    this.contentItemPromise.then(result => {
                        cb(result.contentItems);
                    });
                }

                if (shouldCheckForUpdates) {
                    self._load().then(result => {
                        // If we found updated data, then we need to
                        // trigger the callback.
                        // Or, if we have not triggered the callback at
                        // all yet, then we need to trigger it now.
                        if (result.updated || !triggerWithPartialResult) {
                            cb(result.contentItems);
                        }
                    });
                }

                return this;
            },

            _load() {
                const self = this;
                const klass = $injector.get(self.klassName);

                if (!_.has(klass, 'fieldsForEditorList')) {
                    // it can be undefined (it is in stream.js), but this at least forces you to
                    // think about it
                    throw new Error('fieldsForEditorList must be defined');
                }

                const params = {
                    filters: {
                        published: false,
                        in_users_locale_or_en: null,
                        updated_since: self.lastLoadAt ? self.lastLoadAt.getTime() / 1000 : 0,
                        locale: self.locale,
                    },
                };

                if (klass.fieldsForEditorList) {
                    params['fields[]'] = klass.fieldsForEditorList;
                }

                if (_.includes(['Lesson.Stream'], self.klassName)) {
                    params.filters.user_can_see = null;
                } else if (_.includes(['Lesson'], self.klassName)) {
                    params.filters.archived = false;
                    params.filters.is_practice = false;
                }

                self.lastLoadAt = contentItemEditorLists.now();

                // Some of these requests are really slow.  We should fix that,
                // but as a stop-gap, we can skip queuing so that we don't block other
                // stuff.  We lose retries that way, but I think it's a good tradeoff.
                self.contentItemPromise = klass
                    .index(params, {
                        httpQueueOptions: {
                            shouldQueue: false,
                        },
                    })
                    .then(response => {
                        // Since we just loaded up half-baked items, we don't want
                        // them cached.  Really, this shows that our method of caching
                        // streams is too global.  It is really meant to support navigation
                        // from the student dashboard or library to a stream page, but it
                        // breaks here in the editor.  Similar to how contentItemEditorLists is
                        // a caching mechanism for use in certain places using certain rules,
                        // we should have one in the user-facing part of the site.
                        // We are also busting the LearnerContentCache when navigating out of the editor in editor_index_dir.js
                        // to prevent errors when navigating from the editor to a student facing page.
                        // If the stream cache gets refactored, take a look at that file and see if that is still needed.
                        if (klass.resetCache) {
                            klass.resetCache();
                        }
                        self.contentItems = self.contentItems || {};
                        self.contentItems = _.extend(self.contentItems, _.keyBy(response.result, 'id'));
                        return {
                            contentItems: _.values(self.contentItems),
                            updated: _.some(response.result),
                        };
                    });

                return self.contentItemPromise;
            },
        }));

        let contentItemEditorLists = {
            cacheItems: {
                Playlist: {},
                'Lesson.Stream': {},
                Lesson: {},
                MockIsContentItem: {},
            },

            // we return an object with an onLoad method, rather than
            // a promise, because the handler can be called more than once
            // if we have to refresh data from the server
            load(klassName, localeOrLocales, filters) {
                const self = this;
                let result;

                // If we're loading up multiple locales, then we return a custom
                // object with onLoad and onComplete methods, which delegates
                // to multiple CacheItems, one for each locale
                if (Array.isArray(localeOrLocales)) {
                    result = this._loadMultipleLocales(klassName, localeOrLocales);
                }

                // If we're just loading up a single locale, then we just grab
                // an instance of the CacheItem class (defined above)
                // and return it
                else {
                    const locale = localeOrLocales;
                    if (!this.cacheItems[klassName][locale]) {
                        this.cacheItems[klassName][locale] = new CacheItem(klassName, locale);
                    }

                    result = this.cacheItems[klassName][locale];
                }

                if (filters) {
                    const returnObj = {
                        onLoad(cb) {
                            result.onLoad(contentItems => cb(self._filter(contentItems, filters)));
                            return returnObj;
                        },
                        onComplete(cb) {
                            result.onComplete(contentItems => cb(self._filter(contentItems, filters)));

                            return returnObj;
                        },
                    };
                    return returnObj;
                }
                return result;
            },

            _loadMultipleLocales(klassName, locales) {
                const self = this;
                const contentItemsByLocale = {};
                const results = locales.map(locale => {
                    const result = self.load(klassName, locale);
                    result.locale = locale;
                    return result;
                });

                const returnObj = {
                    onLoad(cb) {
                        // any time new content items are loaded for any of
                        // the locales, trigger the callback
                        results.forEach(result => {
                            result.onLoad(contentItems => {
                                contentItemsByLocale[result.locale] = contentItems;
                                cb(_.chain(contentItemsByLocale).values().flattenDeep().value());
                            });
                        });
                        return returnObj;
                    },
                    onComplete(cb) {
                        // once all content items are loaded for all locales,
                        // trigger the callback
                        const completions = {};
                        results.forEach(result => {
                            result.onComplete(contentItems => {
                                completions[result.locale] = true;
                                contentItemsByLocale[result.locale] = contentItems;
                                if (_.size(completions) === _.size(results)) {
                                    cb(_.chain(contentItemsByLocale).values().flattenDeep().value());
                                }
                            });
                        });
                        return returnObj;
                    },
                };

                return returnObj;
            },

            _filter(contentItems, filters) {
                const self = this;

                let filtered = contentItems.slice(0);
                _.forEach(filters, (val, key) => {
                    if (key === 'locale_pack_id') {
                        filtered = self._filterByLocalePackId(filtered, val, 'filter');
                    } else if (key === 'locale_pack_id_not') {
                        filtered = self._filterByLocalePackId(filtered, val, 'reject');
                    } else if (typeof val === 'function') {
                        filtered = filtered.filter(val);
                    } else {
                        filtered = filtered.filter(contentItem => contentItem[key] === val);
                    }
                });

                return filtered;
            },

            _filterByLocalePackId(contentItems, localePackIds, meth) {
                if (!Array.isArray(localePackIds)) {
                    localePackIds = [localePackIds];
                }

                const filterSet = {};
                _.forEach(localePackIds, localePackId => {
                    filterSet[localePackId] = true;
                });

                return _[meth](contentItems, contentItem => filterSet[contentItem.localePackId]);
            },

            // used for testing
            now() {
                return new Date();
            },
        };

        return contentItemEditorLists;
    },
]);
