import angularModule from 'ContentItemEditor/angularModule/scripts/content_item_editor_module';
import template from 'ContentItemEditor/angularModule/views/locale_pack_selectize.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('localePackSelectize', [
    '$injector',

    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const contentItemEditorLists = $injector.get('contentItemEditorLists');

        return {
            restrict: 'E',
            templateUrl,
            require: 'ngModel', // get a hold of NgModelController
            scope: {
                klassName: '@',
                config: '<?',
                filters: '<?',
                readonly: '<?',
                contentItemCannotBeRemoved: '<?',
                sortSelectedBy: '@?',
            },
            link(scope, _elem, _attrs, ngModelCtrl) {
                NavigationHelperMixin.onLink(scope);

                scope.proxy = {};
                scope.config = scope.config || {};
                scope.contentItemsByLocalePackId = undefined;
                let $$selectedContentItems;

                // Note: The property passed in as the ng-model needs to at least be an empty array.
                Object.defineProperty(scope, 'localePackIds', {
                    get() {
                        return ngModelCtrl.$modelValue;
                    },
                    set(val) {
                        ngModelCtrl.$setViewValue(val);
                        ngModelCtrl.$render();
                    },
                });

                function sortSelected(val) {
                    if (!val) return val;

                    try {
                        val.sort((a, b) => a[scope.sortSelectedBy].localeCompare(b[scope.sortSelectedBy]));
                        return val;
                    } catch (e) {
                        throw new Error('sortSelectedBy value not supported');
                    }
                }

                Object.defineProperty(scope, 'selectedContentItems', {
                    get() {
                        if (scope.sortSelectedBy) return sortSelected($$selectedContentItems);
                        return $$selectedContentItems;
                    },
                    set(val) {
                        if (scope.sortSelectedBy) sortSelected(val);
                        scope.localePackIds = _.map(val, 'localePackId');
                        $$selectedContentItems = val;
                    },
                });

                function setContentItemsFromLocalePackIds() {
                    const localePackIds = scope.localePackIds;
                    const contentItemsByLocalePackId = scope.contentItemsByLocalePackId;

                    if (localePackIds && contentItemsByLocalePackId) {
                        $$selectedContentItems = localePackIds.map(
                            localePackId => contentItemsByLocalePackId[localePackId],
                        );
                    } else {
                        $$selectedContentItems = undefined;
                    }
                }

                function setAvailableContentItems() {
                    // Throw out contentItems that have already been selected
                    scope.availableContentItems = _.reject(scope.allContentItems, contentItem =>
                        _.includes(scope.localePackIds, contentItem.localePackId),
                    );
                }

                function onContentItemChange() {
                    if (scope.localePackIds && scope.allContentItems) {
                        setAvailableContentItems();
                        setContentItemsFromLocalePackIds();
                    } else {
                        $$selectedContentItems = undefined;
                    }
                }

                scope.$watchCollection('localePackIds', onContentItemChange);
                scope.$watch('allContentItems', onContentItemChange);
                scope.$watch('contentItemsByLocalePackId', onContentItemChange);

                scope.$$optionsReloading = false;

                function reloadOptions() {
                    if (scope.$$optionsReloading) {
                        return undefined;
                    }
                    scope.$$optionsReloading = true;
                    scope.allContentItems = undefined;
                    scope.contentItemsByLocalePackId = undefined;
                    const klassName = scope.klassName;
                    return contentItemEditorLists.load(klassName, 'en', scope.filters).onLoad(contentItems => {
                        scope.$$optionsReloading = false;
                        scope.allContentItems = contentItems;
                        scope.contentItemsByLocalePackId = _.keyBy(scope.allContentItems, 'localePackId');
                    });
                }

                scope.$watch('klassName', reloadOptions);
                scope.$watchCollection('filters', reloadOptions);

                scope.$watch('proxy.localePackIdToAdd', localePackId => {
                    if (localePackId) {
                        scope.localePackIds = scope.localePackIds.concat([localePackId]);
                    }
                    scope.proxy.localePackIdToAdd = undefined;
                });

                scope.$watch('config', (config = {}) => {
                    scope.selectizeConfig = angular.extend(
                        {
                            maxItems: 1,
                            labelField: 'titleWithTagAndLocales',
                            valueField: 'localePackId',
                            sortField: 'titleWithTag',
                            searchField: 'titleWithTag',
                        },
                        config,
                    );
                });

                scope.$watch('klassName', klassName => {
                    scope.placeholder = {
                        Playlist: '-- Add a Playlist --',
                        'Lesson.Stream': '-- Add a Course --',
                        Lesson: '-- Add a Lesson --',
                    }[klassName];
                });

                scope.canBeRemoved = contentItem => {
                    if (
                        scope.readonly ||
                        (scope.contentItemCannotBeRemoved && scope.contentItemCannotBeRemoved(contentItem))
                    ) {
                        return false;
                    }
                    return true;
                };

                scope.remove = contentItem => {
                    if (!scope.canBeRemoved(contentItem)) {
                        return;
                    }
                    scope.localePackIds = _.without(scope.localePackIds, contentItem.localePackId);
                };

                scope.open = localePackId => {
                    const contentItem = scope.contentItemsByLocalePackId[localePackId];
                    scope.loadUrl(contentItem.editorUrl, '_blank');
                };
            },
        };
    },
]);
