import angularModule from 'ContentItemEditor/angularModule/scripts/content_item_editor_module';
import template from 'ContentItemEditor/angularModule/views/content_item_image_upload.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('contentItemImageUpload', [
    '$injector',

    $injector => {
        const Upload = $injector.get('Upload');
        const DialogModal = $injector.get('DialogModal');
        const $window = $injector.get('$window');

        return {
            restrict: 'E',
            scope: {
                contentItem: '<',
                uploaded: '&',
                startUpload: '&',
                error: '&',
            },
            transclude: true,
            templateUrl,
            link(scope, elem) {
                Object.defineProperty(scope, 'showDefault', {
                    get() {
                        return elem.find('[custom-content]').html().length === 0;
                    },
                });

                function fileBaseName(fullFileName) {
                    // spaces and special characters will be replaced with
                    // underscores on the server.  Since image_list_editor relies
                    // on the filename that comes back matching the initial filename,
                    // we try to follow their rules.  If there are any other discrepencies,
                    // the image_list_editor will error when the request returns, and
                    // suggest checking the filename for special characters.
                    return (
                        fullFileName
                            .replace(/[^\w\d.]/g, '_')
                            // split on the last '.'
                            .split(/\.[^.]*$/)[0]
                    );
                }

                scope.onImageUploadStart = () => {
                    scope.uploadingImages = true;
                };

                scope.onSuccess = data => {
                    scope.uploadingImages = false;
                    $.each(data.contents.images, (_index, s3Asset) => {
                        const fileName = fileBaseName(s3Asset.file_file_name);
                        scope.uploaded({
                            $$fileName: fileName,
                            $$s3Asset: s3Asset,
                        });
                    });
                };

                // separate method that can be mocked in tests
                scope.allowedType = file =>
                    ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml', 'image/gif', 'image/webp'].includes(
                        file.type,
                    );

                function triggerStartUpload(file) {
                    const reader = new $window.FileReader();

                    // Closure to capture the file information. (Nate doesn't quite
                    // understand why this is structured the way it is, but it was
                    // copied from the internet and it works, so ...)
                    reader.onload = (() => e => {
                        if (scope.startUpload) {
                            scope.startUpload({
                                $$fileName: fileBaseName(file.name),
                                $$dataUrl: e.target.result,
                            });
                        }
                    })(file);

                    reader.readAsDataURL(file);
                }

                scope.$watch('contentItem.image', () => {
                    scope.showRemove = scope.contentItem && scope.contentItem.image;
                });

                scope.removeImage = () => {
                    scope.contentItem.image = null;
                };

                scope.onFileSelect = files => {
                    if (!files || files.length === 0) {
                        return;
                    }

                    const maxSizeMb = 2.5;
                    const maxSizeBytes = maxSizeMb * 1024 * 1024;
                    const errorText = [];

                    const fileNames = _.map(files, 'name');
                    if (_.uniq(fileNames).length < fileNames.length) {
                        DialogModal.alert({
                            content: 'You cannot upload two files with the same name at once.',
                        });
                        return;
                    }
                    // determine files are allowed
                    files.forEach(file => {
                        if (!scope.allowedType(file)) {
                            errorText.push(
                                `'${file.name}' is not an allowed image type (jpg, png, gif, and svg are allowed).`,
                            );
                        }
                        if (file.size > maxSizeBytes) {
                            errorText.push(`'${file.name}' exceeds the maximum upload size of ${maxSizeMb} MB`);
                        }
                    });

                    // if so, error out
                    if (errorText.length > 0) {
                        const errorTextHTML = errorText.join('<br>');
                        DialogModal.alert({
                            content: errorTextHTML,
                            classes: ['server-error-modal'],
                            title: 'Invalid Image',
                        });

                        // clear the files array for re-use
                        elem.find('[ngf-file-select]').val('');
                        return;
                    }

                    files.forEach(file => {
                        triggerStartUpload(file);
                    });

                    scope.onImageUploadStart();

                    Upload.upload({
                        url: scope.contentItem.imageUploadUrl(),
                        data: {
                            images: files,
                        },
                        arrayKey: '[]',
                        timeout: undefined,
                    })
                        .success(scope.onSuccess)
                        .error(() => {
                            if (scope.error) {
                                scope.error();
                            }
                            scope.uploadingImages = false;
                        });
                };
            },
        };
    },
]);
