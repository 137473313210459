import angularModule from 'ContentItemEditor/angularModule/scripts/content_item_editor_module';
import template from 'ContentItemEditor/angularModule/views/content_item_save_publish.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('contentItemSavePublish', [
    '$injector',

    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');
        const $window = $injector.get('$window');
        const scopeTimeout = $injector.get('scopeTimeout');
        const DialogModal = $injector.get('DialogModal');
        const Playlist = $injector.get('Playlist');

        return {
            restrict: 'E',
            scope: {
                contentItem: '<',
                itemHeader: '@',
                goBack: '&',
            },
            transclude: true,
            templateUrl,
            link(scope, element) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                //---------------------------
                // MetaData Management
                //---------------------------

                function resetMetadata() {
                    scope.metadata = {
                        should_publish: false,
                        should_unpublish: false,
                    };
                }

                resetMetadata();

                scope.$watch('metadata.should_publish', val => {
                    if (val) {
                        scope.metadata.should_unpublish = false;
                    }
                });

                scope.$watch('metadata.should_unpublish', val => {
                    if (val) {
                        scope.metadata.should_publish = false;
                    }
                });

                //---------------------------
                // Save and Delete
                //---------------------------

                scope.onSaved = () => {
                    const saveButton = element.find('button[data-id="save"]');
                    const text = saveButton.text();
                    saveButton.text('Saved');
                    scopeTimeout(
                        scope,
                        () => {
                            saveButton.text(text);
                            // re-enable submission after response is returned
                            scope.preventSubmit = false;
                        },
                        1000,
                    );
                };

                scope.onSaveError = () => {
                    // re-enable submssion after response is returned
                    scope.preventSubmit = false;
                };

                scope.delete = () => {
                    if ($window.confirm(`You sure you want to delete "${scope.contentItem.title}"?`)) {
                        scope.preventSubmit = true;
                        scope.contentItem
                            .destroy()
                            .then(() => {
                                safeApply(scope, () => {
                                    scope.preventSubmit = false;
                                    scope.goBack();
                                });
                            })
                            .catch(() => {
                                scope.preventSubmit = false;
                            });
                    }
                };

                scope.save = () => {
                    if (scope.contentItem) {
                        scope.contentItem.entity_metadata.save().then(() => {
                            // disable submission until response has returned
                            scope.preventSubmit = true;
                            scope.contentItem
                                .save(scope.metadata, {
                                    timeout: undefined,
                                })
                                .then(scope.onSaved, scope.onSaveError);
                        });
                    }
                };

                scope.duplicatePlaylist = playlist => {
                    if (!playlist.isA(Playlist)) {
                        throw new Error('Calling duplicatePlaylist() on a non-playlist');
                    }

                    DialogModal.alert({
                        content:
                            '<duplicate-content-item content-item="contentItem" hide-is-translation="true"></duplicate-content-item>',
                        scope: {
                            contentItem: scope.contentItem,
                            hideIsTranslation: true,
                        },
                        classes: ['overflow-visible'],
                        size: 'small',
                    });
                };
            },
        };
    },
]);
