import 'ng-file-upload';

import 'ContentItem/angularModule';
import 'FrontRoyalSpinner/angularModule';
import 'LearnerContentCache/angularModule';
import 'SafeApply/angularModule';
import 'SearchEngineOptimization/angularModule';
import 'Sequence/angularModule';
import 'TimeoutHelpers/angularModule';
import 'guid/angularModule';

export default angular.module('FrontRoyal.ContentItemEditor', [
    'FrontRoyal.ContentItem',
    'LearnerContentCache',
    'timeoutHelpers',
    'SiteMetadata',
    'safeApply',
    'FrontRoyalSpinner',
    'ngFileUpload',
    'guid',
    'Sequence',
]);
